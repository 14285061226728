.badge {
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 5px;
  top: 5px;
  display: inline-block;
}

.headline {
  margin-bottom: 20px;
}
