.margin-bottom5px {
  margin-bottom: 15px;
}
.label-text {
  margin-bottom: 5px;
  font-weight: 500;
  text-align: right !important;
  width: 100% !important;
}

.label-text-left-override {
  margin-bottom: 5px;
  font-weight: 500;
  margin-left: 20px;
  width: 100% !important;
}
.error {
  color: red;
  padding: 5px !important;
}
.hidden-field {
  height: 0px;
}

.view-only-text {
  margin: 0;
}

.required-field {
  color: red;
}

.upper-case {
  text-transform: uppercase;
}

// --------------- TOGGLE SLIDER ------------------
@dark-blue: #0057b8;
@light-blue: #739bd4;
@light-gray: #949191;

.switch-btn {
  width: 36px;
  height: 14px;
  background: @light-gray;
  position: relative;
  cursor: pointer;
  border-radius: 25px;
}

.switch-btn:before {
  content: "";
  position: absolute;
  height: 18px;
  width: 18px;
  background: white;
  top: -2px;
  border-radius: 50%;
  left: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 220ms ease-out;
}

label[toggle-value="true"] > .switch-btn:before {
  left: 18px !important;
  background: @dark-blue !important;
  box-shadow: none !important;
}

label[toggle-value="true"] > .switch-btn {
  background: @light-blue !important;
}

label.switch > input {
  display: none;
}
