.margin-top10px {
  margin-top: 10px !important;
}
.margin-bottom5px {
  margin-bottom: 15px;
}
.displayNone {
  display: none;
}
.displayBlock {
  display: block;
}
