.patients-show {
  .panel-headline {
    margin-bottom: 20px;
  }

  h2 {
    margin: 0;
  }

  .patient-details {
    p, br {
      font-size: 12px;
      margin: 0;
    }
  }

  iframe {
    margin: -20px -20px 0;
    width: 100%;
  }

  .avatar {
    display: inline-block;
    float: left;
    height: 100px;
    width: 100px;
    margin-right: 10px;

    background-position: center center;
    background-size: cover;
  }

  .share-code {
    text-align: center;

    h4 {
      line-height: 20px;
    }

    p {
      margin: 10px 0;
    }
  }

  .qrcode {
    float: left;
    display: inline-block;
    margin-right: 10px;
  }

  .assessment-summary {
    width: 120px;
    height: 120px;

    float: left;
    background-color: #3582C6;
    color: #FFF;
    text-align: center;

    margin: 0 10px 0 0;
    border-radius: 10px;

    padding: 10px;

    h4 {
      font-size: 40px;
      line-height: 40px;
      margin: 15px 0 5px 0;
    }

    p {
      margin: 0;
    }
  }

  .wound-assessment {
    margin-bottom: 10px;
    cursor: pointer;
  }

  .wound-image {
    width: 120px;
    height: 120px;

    background-position: center center;
    background-size: cover;
    background-color: #CCC;
  }
}
