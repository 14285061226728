.load-more-card {
  width: 250px;
  height: 330px;
}

.view-more-button {
  vertical-align: middle;
  // font-size: 40px !important;
  height: 100%;
}

.view-more-plus-label {
  font-size: 40px;
  display: block;

  :global .btn:hover {
    text-decoration: none !important;
  }
}
