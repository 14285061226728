.patient-column-component {

  .avatar {
    display: inline-block;
    float: left;
    margin: 0 10px 2px 0;
    height: 80px;
    width: 80px;

    background-position: center center;
    background-size: cover;
  }

  p {
    margin-bottom: 5px;
  }
}
