.filter-component-list {
  padding: 0;
  list-style-type: none;

  > li {
    margin: 20px 0px 40px 0px;
    // max-height: 400px;
  }
}

.select-message {
  margin: 20px 0px 40px 0px;
}
