.input-range-container {
  display: flex;
  height: 34px;
}

.input-component-wrapper {
  width: 90px;
}

.hyphen-wrapper {
  margin: 0px 10px;
}

.hyphen {
  font-size: 24px;
  font-weight: 700;
}
