.assessment-card {
  display: inline-block;
  height: 230px;

  h4 {
    margin: 0 0 0 0;
    font-size: 16px;
  }

  p {
    color: #666;
    margin: 0 0 2px 0;
    font-size: 12px;
  }
}

.assessment-card-left {
  display: inline-block;
  vertical-align: top;
  height: inherit;
  width: 90px;
}

.assessment-card-right {
  display: inline-block;
  vertical-align: top;
  height: inherit;
  width: 140px;
  overflow-y: scroll;
}

.assessment-heading {
  margin-bottom: 7px;

  p {
    font-size: 14px;
  }
}

.assessment-image {
  display: block;
  height: 80px;
  width: 80px;

  background-position: center;
  background-color: #ccc;
  background-size: cover;
}

.last-assessed {
  font-size: 12px;
}

.in-house-acquired-label {
  margin-top: 4px;
  padding-left: 0.5px;

  svg {
    display: inline-block;
    width: 15px;
    height: 15px;
  }

  p {
    display: inline-block;
    padding-left: 4px;
  }

  path {
    fill: #d0021b;
  }
}

.wound-image {
  width: 80px;
  height: 80px;

  background-position: center center;
  background-size: cover;
  background-color: #ccc;
}
