@import "../../css_attributes/variables.less";

.avatar {
  height: 50px;
  width: 50px;

  background-position: center center;
  background-size: cover;
}

.media-heading {
  font-size: 14px;
  margin-top: 5px;
}

.patient-media {
  padding: 0px 0px 0px 0px !important;
}

.patient-media-left {
  padding: 5px 0px 0px 5px;
}

.patient-media-body {
  padding-top: 5px;
}
