.assessment-button {
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  margin: 5px 0px 5px 0px;
}
.assessment-button:disabled {
  cursor: not-allowed;
  opacity: 0.65;
  box-shadow: none;
}
