.container {
  margin: 18px 18px 18px 18px;
}

.title {
  font-size: 30px;
  font-weight: bold;
}

.reduced-line-height {
  line-height: 100%;
}

.subtitle {
  font-size: 12px;
  font-weight: bold;
  padding: 0 0 0 0;
  margin: 2px 0 2px 0;
}

.subtitle > span {
  color: gray;
}

.badge {
  display: inline-block;
  padding-top: 10px;
  padding-left: 10px;
  float: right;
}

.icon-small {
  width: 30px;
  height: 30px;
  fill: #333;
  float: left;
  margin-top: 20px;
}
