.tabs {
  a {
    border-radius: 20px 20px 0 0 !important;
  }
}

.marginBottom40px {
  margin-bottom: 40px !important;
}

.marginBottom20px {
  margin-bottom: 20px !important;
}

// Not in use - this will be to add a border to the tab content (should be rendered according to a prop)
.tab-content-border {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px;
}
