.launch-error-container {
  margin-top: 10%;
  text-align: center;
}

.error-message {
  font-size: 2rem;
  font-weight: bold;
}

.error-cta {
  font-size: 1.6rem;
}
