.container {
  width: 100%;
  height: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.fix-aspect-ratio {
  width: 100%;
  position: relative;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
