.container {
  display: flex;
  background-color: #555555;
  color: white;
  height: 50px;
  padding: 15px 18px 0px 18px;
  font-weight: bold;
}

.title {
  flex-grow: 2;
  text-align: left;
}

.action-buttons {
  display: flex;
  justify-content: space-evenly;
}

.icon-button {
  margin: 0 5px;
  cursor: pointer;
}
