@import "./variables.less";
@import "./bootstrap-variables-overides.less";

//@import "~bootstrap/less/variables.less";
@import "~bootstrap/less/mixins.less";

// Reset and dependencies
@import "~bootstrap/less/normalize.less";
@import "~bootstrap/less/print.less";
@import "~bootstrap/less/glyphicons.less";

// Core CSS
@import "~bootstrap/less/scaffolding.less";
@import "~bootstrap/less/type.less";
//@import "~bootstrap/less/code.less";
@import "~bootstrap/less/grid.less";
@import "~bootstrap/less/tables.less";
@import "~bootstrap/less/forms.less";
@import "~bootstrap/less/buttons.less";
@import "~bootstrap/less/progress-bars.less";

// Components
@import "~bootstrap/less/component-animations.less";
@import "~bootstrap/less/dropdowns.less";
@import "~bootstrap/less/button-groups.less";
@import "~bootstrap/less/input-groups.less";
@import "~bootstrap/less/navs.less";
@import "~bootstrap/less/navbar.less";
@import "~bootstrap/less/breadcrumbs.less";
//@import "~bootstrap/less/pagination.less";
//@import "~bootstrap/less/pager.less";
@import "~bootstrap/less/labels.less";
//@import "~bootstrap/less/badges.less";
//@import "~bootstrap/less/jumbotron.less";
//@import "~bootstrap/less/thumbnails.less";
@import "~bootstrap/less/alerts.less";
//@import "~bootstrap/less/progress-bars.less";
@import "~bootstrap/less/media.less";
@import "~bootstrap/less/list-group.less";
@import "~bootstrap/less/panels.less";
//@import "~bootstrap/less/responsive-embed.less";
//@import "~bootstrap/less/wells.less";
@import "~bootstrap/less/close.less";

// Components w/ JavaScript
@import "~bootstrap/less/modals.less";
@import "~bootstrap/less/tooltip.less";
//@import "~bootstrap/less/popovers.less";
//@import "~bootstrap/less/carousel.less";

// Utility classes
@import "~bootstrap/less/utilities.less";
@import "~bootstrap/less/responsive-utilities.less";
@import "./daterange.less";
@import "../node_modules/react-datepicker/dist/react-datepicker.css";

//@import url(https://fonts.googleapis.com/css?family=Lato:400,300,700);
//@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,700);
//@import url(https://fonts.googleapis.com/css?family=Oxygen:400,300,700);

// @font-face {
//   font-family: "Montserrat";
//   src: url("./fonts/Montserrat-Bold.woff2") format("woff2");
//   font-weight: 700;
// }
//
// @font-face {
//   font-family: "Montserrat";
//   src: url("./fonts/Montserrat-Regular.woff2") format("woff2");
//   font-weight: 500;
// }
//
// @font-face {
//   font-family: "Montserrat";
//   src: url("./fonts/Montserrat-Light.woff2") format("woff2");
//   font-weight: 400;
// }
//
// @font-face {
//   font-family: "Montserrat";
//   src: url("./fonts/Montserrat-UltraLight.woff2") format("woff2");
//   font-weight: 300;
// }
//
// @font-face {
//   font-family: "Montserrat";
//   src: url("./fonts/Montserrat-Hairline.woff2") format("woff2");
//   font-weight: 200;
// }

@font-face {
  font-family: "Avenir";
  src: local("Avenir Next"), url("./fonts/avenir-book.woff2") format("woff2");
}

@font-face {
  font-family: "Avenir";
  src: url("./fonts/AvenirNextLTPro-Bold.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Avenir";
  src: url("./fonts/AvenirNextLTPro-Demi.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Avenir";
  src: url("./fonts/AvenirNextLTPro-Regular.woff") format("woff");
  font-weight: 400;
}

//h1, h2, h3 {
//  font-weight: 200;
//}
//
//h4 {
//  font-weight: 300;
//}

label {
  font-weight: 500;
}

strong {
  font-weight: 500;
}

hr {
  margin: 7px 0px;
}
// add to node_modules/bootstrap/less/modals.less
@media (min-width: @screen-md-min) {
  .modal-lg {
    max-width: 1500px;
  }
}
