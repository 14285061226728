.display-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.non-wound-study-badge-container {
  width: 50px;
  height: 50px;
  padding: 0px !important;
  margin: 0px 5px 5px 5px;
}
.lock-icon-css {
  margin-right: 5px;
  font-size: 21px;
}
.float-left {
  float: left;
}
.pdf-button {
  background-color: #0057b8;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
}
.pdf-button:disabled {
  cursor: not-allowed;
  opacity: 0.65;
  box-shadow: none;
}
.float-right {
  float: right;
}
.display-flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
