.wound-card-list {
  padding: 0;

  li {
    vertical-align: top;
    list-style-type: none;
    display: inline-block;
    margin: 10px 20px 20px 0;
  }
}

.wound-card-list-container {
  min-height: 800px;
}
.width-align-center {
  width: 100% !important;
  text-align: center;
}
