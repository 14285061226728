table.survey-tally-table {
  overflow-x: auto;
  display: block;

  thead {
    th {
      padding: 5px;
      text-align: center;
      font-weight: 500;
      border-bottom: 2px solid #000;
    }
  }

  tbody {
    tr {
      text-align: center;
      td {
        border-top: 0px;
      }
    }
    tr:nth-child(2n + 1) {
      .dark-background {
        background-color: darken(#ffffff, 5%);
      }
    }
    tr:nth-child(2n) {
      background-color: darken(#ffffff, 3%);

      .dark-background {
        background-color: darken(#ffffff, 10%);
      }
    }
  }
}

.right-border {
  border-right: 1px solid #000;
}

span.details-link {
  color: #0000ee;
  cursor: pointer;
}

.total-row {
  border-bottom: 2px solid #000;
}

.wound-type-row {
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}

.left-align {
  text-align: left;
}

.left-align-indent {
  text-align: left;
  padding-left: 25px !important;
  font-style: italic;
}

.left-align-indent-location {
  text-align: left;
  padding-left: 25px !important;
}

.left-align-header {
  text-align: left !important;
  padding-left: 8px !important;
}

.red-attention-text {
  color: #d0021b;
}
