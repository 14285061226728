@import "../variables.less";

.flash-messages {
  .flash-message {
    background-color: @brand-primary;
    color: #FFF;
    height: 40px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    padding: 11px 10px 10px;

    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
  }

  .flash-message-success {
    background-color: @brand-success;
  }

  .flash-message-info {
    background-color: @brand-info;
  }

  .flash-message-error {
    background-color: @brand-danger;
  }

  button {
    margin: -10px 10px;
    padding: 6px 10px 4px;
  }

  .close-button {
    color: #FFF;
    vertical-align: middle;
    font-size: 15px;
    line-height: 15px;
    float: right;
    margin-top: 1px;
    cursor: pointer;
  }
}

.flash-message-transition-enter {
  opacity: 0.01;
  top: -40px;

  &.flash-message-transition-enter-active {
    opacity: 1;
    top: 0px;
    transition: all 500ms ease-in;
  }
}

.flash-message-transition-leave {
  opacity: 1;
  top: 0px;

  &.flash-message-transition-leave-active {
    opacity: 0.01;
    top: -40px;
    transition: all 500ms ease-in;
  }
}
