.account-view {
  a:hover {
    text-decoration: none;
  }

  p {
    margin-top: 20px;
    font-size: 20px;
  }
}
.margin-top-10px {
  margin-top: 10px;
}

.settings-buttons {
  display: flex;
  margin-top: 20px;
  div {
    padding-right: 15px;
  }
}
