.series-details-header-container {
  margin-top: 20px;
}

.wound-image-container {
  width: 100px;
  height: 100px;
}

.series-details-header-text-container {
  h2 {
    margin: 0;
  }

  h3 {
    font-size: 18px;
    margin: 4px 0 0 0;
    color: darkgray;
    padding: 0 0 0 0;
  }
}

.wound-assessment {
  .wound-assessment__header {
    .wound-assessment__header__label {
      margin-left: 110px;
    }

    .wound-assessment__header__thumbnail {
      margin-top: 21px;
    }
  }
}
