.container {
  padding: 12px 0px;
  display: flex;
}

.in-progress {
  color: gray;
}

.filename {
  flex-grow: 2;
}

.truncate-filename {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-wide {
  width: 350px;
}

.text-align-right {
  text-align: right;
}

.download-icon {
  font-size: 18px;
  color: #0057b8;
  cursor: pointer;
}

.download-icon-disabled {
  font-size: 18px;
  color: #77b4f8;
  cursor: pointer;
}

.close-icon {
  padding-right: 12px;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.close-icon circle {
  fill: #dc3545;
  stroke: none;
}
