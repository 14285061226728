.margin-bottom5px {
  margin-bottom: 15px;
}
.label-text {
  margin-bottom: 5px;
  font-weight: 500;
  text-align: right !important;
  width: 100% !important;
}
.error {
  color: red;
  padding-left: 10px;
}

.required-field {
  color: red;
}
