@noimage-url: "https://s3.amazonaws.com/bdsm-dev-east/assets/wound-camera-icon.png";
.image-container {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  image-orientation: from-image;
}

.spin-box {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  box-shadow: 15px 15px #4f4d49, -15px 15px #dfdfdf, -15px -15px #4f4d49,
    15px -15px #dfdfdf;
  -webkit-animation: spin ease infinite 4s;
}

@-webkit-keyframes spin {
  0%,
  100% {
    box-shadow: 15px 15px #4f4d49, -15px 15px #dfdfdf, -15px -15px #4f4d49,
      15px -15px #dfdfdf;
  }
  25% {
    box-shadow: -15px 15px #dfdfdf, -15px -15px #4f4d49, 15px -15px #dfdfdf,
      15px 15px #4f4d49;
  }
  50% {
    box-shadow: -15px -15px #4f4d49, 15px -15px #dfdfdf, 15px 15px #4f4d49,
      -15px 15px #dfdfdf;
  }
  75% {
    box-shadow: 15px -15px #dfdfdf, 15px 15px #4f4d49, -15px 15px #dfdfdf,
      -15px -15px #4f4d49;
  }
}
.no-image-css {
  background-size: 50%;
  background-color: #d4d4d4;
  background-image: url("@{noimage-url}");
}
