.headerStyle {
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  h1 {
    font-size: 35px;
    margin-bottom: 10px;
  }
  h2 {
    margin: 0;
    padding-bottom: 5px;
  }

  h3 {
    font-size: 15px;
    color: darkgray;
    margin: 0;
    padding-bottom: 0px;
  }
}

.imageStyle {
  padding-right: 5px;
  height: 600px;
  overflow-y: scroll;
}

.buttonContainer {
  padding-left: 15px;
  height: 20px;
  float: right;
}

.esignatureForm {
  padding-left: 15px;
}

.encounterModal {
  padding-left: 15px;
}

.modalHeight {
  min-height: 600px;
}

.exitStyle {
  float: right;
  font-size: 16px;
  color: #555555;
  padding: 10px;
  cursor: pointer;
}

.revisionNavigationBody {
  height: 850px;
  overflow-y: scroll;
}
