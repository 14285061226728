.revision-visuals-container {
  width: 100%;
  height: 100%;
}

.image-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.image-modal-body {
  height: 670px;
  width: 470px;
}

.modal-container {
  width: 470px;
  margin-right: auto;
  margin-left: auto;
}

.imageText {
  position: absolute;
  top: 10px;
  right: 15px;
  color: black;
  font-size: 14px;
  font-weight: 500;
}
