.default {
  width: 150px;
  margin-right: 5px;
}

.activeClass {
  pointer-events: none;
}

.buttonBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
