.role-description {
  font-size: 12px;
}

.role-name {
  font-weight: 500;
  margin-bottom: 0px;
}

.font-grey {
  color: #555555;
}

.divisor {
  border-bottom: 2px solid #b9b9b9;
}

.section-name {
  margin-top: 40px;
  margin-bottom: 0px;
  padding-bottom: 10px;
}

.table-rows-middle-vertical-align {
  td {
    vertical-align: middle !important;
  }
}

.no-margin {
  margin: 0px;
}

.style-buttons {
  padding-bottom: 30px !important;
}
