.header-container {
  padding: 20px;
}

.breadcrumb-headline {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 40px !important;
}
.margintop8px {
  margin-top: 8px !important;
}

.margintop11px {
  margin-top: 11px !important;
}

.dashboard-btn {
  margin-top: 11px !important;
  color: #333;
  background-color: #fff;
  border-color: #ccc !important;
}

.dashboard-btn:focus {
  color: black !important;
}

.dashboard-btn:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad !important;
}
