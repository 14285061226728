.margin-bootom20px {
  margin-bottom: 20px;
}
.group-textbox {
  input[type="text"] {
    width: 100%;
    margin-right: 5px;
    margin-bottom: 15px;
    border-radius: 5px !important;
    border-radius: 5px !important;
  }
  select {
    width: 100% !important;
    margin-right: 27px !important;
    margin-bottom: 15px !important;
    border-radius: 5px !important;
    border-radius: 5px !important;
  }
  select:last-child {
    margin-right: 0;
  }

  width: 100% !important;
}

.group-textbox > div {
  float: left !important;
  width: 50% !important;
  padding-right: 1% !important;
}

.group-textbox > div:last-child {
  padding-right: 0 !important;
}
.label-text {
  margin-bottom: 5px;
  font-weight: 500;
  text-align: right !important;
  width: 100% !important;
}

.style-buttons {
  padding-bottom: 30px !important;
}
