.checkbox-list-container {
  ul {
    padding: 0px;
    margin: 0px;
  }
}

.checkbox-expansion {
  display: flex !important;
  height: 100%;
}

.checkbox-label {
  max-width: 89%;
  word-wrap: break-word;
  hyphens: auto;
}

.checkbox {
  max-width: 89%;
  word-wrap: break-word;
  hyphens: auto;
}

.list-item {
  position: relative;
}

.edit-filter-button {
  position: absolute;
  top: 0;
  right: 0;

  &:hover {
    cursor: pointer;
  }

  &:hover path {
    fill: #000;
  }
}
