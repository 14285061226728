.link {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.theme-light {
  color: white;
  &:hover {
    color: white;
  }
}

.theme-dark {
  color: blue;
  &:hover {
    color: blue;
  }
}
