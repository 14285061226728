.badge-line {
  width: 20px;
  height: 5px;
  position: relative;
  margin-right: 5px;
  top: -2px;
  display: inline-block;
}

.badge-box {
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 5px;
  top: 5px;
  display: inline-block;
}

.data-point {
  background-color: black;
}

.error-tolerance {
  background-color: gray;
}

.average {
  background-color: rgba(0, 100, 0, 0.9);
}

.overperforming {
  background-color: rgba(0, 0, 100, 0.3);
}

.underperforming {
  background-color: rgba(100, 0, 0, 0.3);
}

.headline {
  margin-bottom: 20px;
}
