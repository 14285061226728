.date {
  color: gray;
  font-size: 12px;
}

.icon-small {
  margin-right: 5px;
}

.align-left-margin {
  margin-left: 12px;
}

.action-link {
  cursor: pointer;
  margin-left: 10px;
}

.load-more-container {
  margin-top: 10px;
  margin-left: 14px;
}
