@import "../../css_attributes/variables.less";

.sidebar-container {
  height: 100vh;
  position: relative;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.sidebar-filter {
  //display: none;
  align-self: stretch;
}

.sidebar-toggle {
  z-index: 2;
  position: absolute;
  right: -20px;
  top: 40px;
  color: white;
  cursor: pointer;
  transition: 0.2s;

  width: 20px;
  height: 80px;
  border-radius: 0px 5px 5px 0px;
  background-color: #0057b8;
}

.sidebar-toggle:hover {
  right: -50px;
  width: 50px;
}

.sidebar-toggle-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sidebar-pcc {
  background-color: #97b469 !important;
}
