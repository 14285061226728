.select {
  height: 40px;
  width: 100%;
  font-size: 120%;
  padding-left: 1%;
}

.select-container {
  text-align: center;
  margin-top: 5%;
}

.button-container {
  margin-top: 8%;
  text-align: center;
}
