.bootstrap-panel {
  margin-top: 5px !important;
  padding-top: 5px;

  a {
    display: flex !important;
    align-items: center;
    text-decoration: none !important;
    cursor: pointer;
  }
}

.display-flex {
  display: flex;
  align-items: center;
}

.link-text {
  height: 100%;
}

.link-text:hover {
  text-decoration: underline;
}
