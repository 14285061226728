.margin-bottom5px {
  margin-bottom: 15px;
}

ul {
  list-style-type: none;
}
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
}

.label-text {
  margin-bottom: 5px;
  font-weight: 500;
  text-align: right !important;
  width: 100% !important;
}

.error {
  color: black;
  padding: 5px !important;
  font-style: italic;
}

.required-field {
  color: red;
}
