.margin-bottom20px {
  margin-bottom: 20px;
}
.group-textbox {
  input[type="text"] {
    width: 100%;
    margin-right: 5px;
    border-radius: 5px !important;
    border-radius: 5px !important;
    margin-bottom: 5px;
  }
  width: 100% !important;
}
.label-text {
  margin-bottom: 5px;
  font-weight: 500;
  text-align: right !important;
  width: 100% !important;
}
.group-textbox > div {
  float: left !important;
  width: 33.3% !important;
  padding-right: 1% !important;
}
.group-textbox > div:last-child {
  padding-right: 0 !important;
}

.margin-bottom15px {
  margin-bottom: 15px;
}

.style-buttons {
  padding-bottom: 30px !important;
}

.required-field {
  color: red;
}
