.spacing-small-row > div {
  margin-left: 10px;
  margin-right: 10px;
}

.spacing-large-row > div {
  margin-left: 20px;
  margin-right: 20px;
}

.spacing-small-column > div {
  margin-top: 10px;
  margin-bottom: 10px;
}

.spacing-large-column > div {
  margin-top: 20px;
  margin-bottom: 20px;
}
