.event-string-list {
  padding-left: 0px;
}

.load-more-container {
  width: 100%;
  height: 40px;
  padding-top: 20px;
  padding-bottom: 40px;
  text-align: center;
}
