.comparison-view-container {
  display: flex;
  overflow-x: scroll;
}

.plus-icon {
  position: relative;
  height: 86px;
  width: 86px;
  // center on 750px by 400px card
  margin: 372px 157px 332px;
  cursor: pointer;
}
.plus-icon::after {
  content: "";
  position: absolute;
  right: -157px;
  width: 1px;
  height: 1px;
}
