.selectors-container {
  width: 40%;
}

.selectors-container > div {
  padding: 5px 0px;
}

.selectors-container select {
  margin-top: 5px;
}
