@import "../../css_attributes/variables.less";

.sidebar-filters-container-pcc {
  background-color: #97b469 !important;
}

.sidebar-filters-container {
  width: @sidebar-width - @sidebar-contextual-menu-width;
  display: inline-block;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
  overflow-y: scroll;
  color: #fff;

  h5,
  h3 {
    display: inline-block;
    padding-right: 50px;
  }

  ul {
    list-style-type: none;
  }
}

.filter-component-list {
  padding: 0px;
  margin-bottom: 10px;

  li {
    border-bottom: 1px solid white;
    padding: 5px 0px 5px 0px;

    li {
      border-bottom: 0px;
      padding: 0px;
    }
  }
}

.secondary-filter-button {
  position: absolute;
  top: 14px;
  right: 11px;
}
.btn-primary-filter {
  background-color: #0041b8 !important;
  border-color: #0057b8 !important;
}
.btn-primary-filter:focus {
  outline-color: #1c66d6 !important;
  color: white !important;
}
.btn-primary-filter:hover {
  color: white !important;
  background-color: #0b00b8 !important;
}
