.label-content {
  display: flex !important;
  font-weight: 400;
}

h3 {
  padding: 0 0 15px 0;
}

h4 {
  padding: 0 0 10px 0;
}
