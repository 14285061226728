@import "../../css_attributes/variables.less";

.sidebar-nav-menu {
  width: @sidebar-contextual-menu-width;
  height: 100vh;
  background-color: @gray-lighter;
  display: inline-block;
  position: relative;
  vertical-align: top;
  color: #fff;
  text-align: center;
  font-size: 10px;

  .swift-logo {
    display: block;
    padding: 9px 5px 4px;
    svg {
      width: 40px;
      height: 40px;
    }
  }

  .medline-logo {
    display: block;
    color: #f56076;
    svg {
      width: 50px;
      height: 50px;
    }
  }

  .logo-nav {
    margin-top: 7px;
    margin-bottom: 2px;
  }

  .bottom-nav {
    bottom: 20px;
  }

  .active-nav-link {
    color: #000;
    background-color: #bbb !important;

    svg {
      path,
      ellipse,
      polygon {
        fill: #333;
      }
    }
  }

  svg {
    width: 30px;
    height: 30px;
    path,
    ellipse,
    polygon {
      fill: #333;
    }
  }

  a {
    color: #000;
    padding: 10px 5px !important;

    &:hover {
      .active-nav-link;
    }
  }
  .paddingleft11px {
    padding-left: 9px !important;
  }
}
