.active-button-default-no-margins {
  padding: 0px;
  margin: 0px !important;
}

.active-button-default-no-margins:focus {
  outline: none !important;
}

.button-default-no-margins {
  padding: 0px;
  margin: 0px !important;
}

.button-default-no-margins:focus {
  outline: none !important;
}
