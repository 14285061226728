// container for image and canvas
.revision-visuals-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.canvas-container {
  overflow: hidden;
  position: relative;
}

.region-canvas {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  left: 0;
}

.image-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.frame-container {
  position: relative;
}

.image-options {
  display: flex;
  position: absolute;
  margin-bottom: 1px;
  z-index: 3;
  left: 0;
  right: 0;
  justify-content: space-between;

  .image-icons {
    display: inline-flex;
  }

  a {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0;
    font-size: 200%;
    color: white;
    stroke: white;
  }

  a:hover {
    stroke: #333;
  }

  .frame-label {
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    min-width: 120px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.image-options-fluoro {
  display: flex;
  position: absolute;
  margin-bottom: 1px;
  z-index: 3;
  left: 0;
  right: 0;
  justify-content: space-between;

  .image-icons {
    display: inline-flex;
  }

  a {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0;
    padding: 0px;
    font-size: 200%;
    color: white;
    stroke: white;
  }

  a:hover {
    stroke: #333;
  }

  .frame-label {
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    min-width: 120px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 55px;
    height: 40px;
  }
}

.banner-options {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 3;
  width: 100%;
}

.banner-text {
  text-align: center;
  margin: 9px 9px 9px 9px;
  color: white;
  font-weight: 400;
}

.revision-table-row {
  td {
    width: 50%;
  }
}

.context-table-row {
  td {
    width: 30%;
  }
}

.thermal-scale {
  width: 100%;
  display: flex;
  margin: 0 auto;
}

.thermal-image-container {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  image-orientation: from-image;
}

.dvh-image {
  background-color: rgba(0, 0, 0, 0.4);
  height: 650px;
  width: 650px;
}
