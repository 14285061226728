.date-picker-range-element {
  margin-right: 5px;
  margin-left: 5px;
}

.date-picker-range-first-element {
  margin-right: 5px;
}

.date-picker-range-last-element {
  margin-left: 5px;
}
