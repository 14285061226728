.dropdown-wrapper {
  margin-bottom: 12px;
}

.select-option {
  min-width: 195px;
  height: 35px;
}

.selected-option {
  width: 195px;
  height: 35px;
  display: flex;
  padding-left: 9px;
  align-items: center;
}

.option-button {
  min-width: 120px;
}
