@import "../../css_attributes/variables.less";

.qa-note-input-container {
  background-color: #e9e7fd;
  width: 100%;
  padding: 15px;
  margin: 10px 0px;
}

.qa-note-input-field {
  background-color: transparent;
  border: none;
  width: 100%;
  resize: none;
}

.qa-note-title {
  font-weight: bold;
}

.qa-note-error {
  font-weight: 450;
  color: #dc3545;
}

.qa-note-success {
  font-weight: 450;
  color: #28a745;
}

.qa-note-hint {
  font-size: 0.9em;
  font-weight: normal;
  color: #5b5b5b;
  margin: 0px 10px;
}

.qa-note-footer {
  margin: 0px 5px !important;
}

.qa-note-status {
  padding: 0px !important;
}

.qa-note-button-primary {
  background-color: #9c6df7;
  color: white;
  border-radius: 5px;
  border: 1px solid #9c6df7;
  width: 64px;
  height: 32px;
}

.qa-note-button-primary:hover {
  background-color: #b492f7;
}

.qa-note-button-primary:disabled {
  opacity: 0.4;
  background-color: #9c6df7;
}

.qa-note-button-secondary {
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #8c8c8c;
  width: 64px;
  height: 32px;
}

.qa-note-button-secondary:hover {
  background-color: #b492f7;
  color: white;
  border: 1px solid #9c6df7;
}

.qa-note-button-secondary:disabled {
  opacity: 0.4;
  background-color: #d8ced9;
  color: black;
}
