.my-dashboard-iframe {
  width: 100%;
  height: calc(~"100vh - 100px");
  overflow: auto;
  border: white 1px solid;
  margin: 0px;
  padding: 0px;
}

.iframe-clicker-overlay {
  width: calc(~"100vw - 500px");
  height: calc(~"100vh - 200px");
  position: absolute;
  cursor: pointer;
}

.loading-screen-overlay {
  width: calc(~"100vw - 340px");
  height: calc(~"100vh - 100px");
  background: white;
  position: absolute;
}
