@import "./base.less";

@primary-color: #202b45;
@secondary-blue: @primary-color;
@primary-gray: #e1e1e1;
@badge-text-color: #333333;

body {
  overflow-x: hidden;
}

// Background color styles
.primary-background {
  background: @primary-color;
}

.badge-background {
  background: @primary-gray;
  color: @badge-text-color;
}

.secondary-background {
  background: rgba(233, 246, 255, 0.37);
}

.primary-background {
  .dashboard-btn {
    color: black !important;
    background-color: white;
  }

  .dashboard-btn:hover {
    color: black !important;
  }

  .dashboard-btn:focus {
    color: black !important;
  }
}

.float-right {
  float: right;
}

// Buttons
.dashboard-btn {
  background-color: @primary-color;
  border-color: #000;
  color: white;
}

.dashboard-btn:focus {
  background-color: @primary-color;
  outline-color: #1c66d6 !important;
  color: white !important;
}

.dashboard-btn:hover {
  color: white !important;
  background: rgba(32, 43, 69, 0.8);
}

// vvvvvvv Needs to be refactored vvvvvvvv

// ----------------- Reset password page ----------------------- //
.reset-password-view {
  .swift-logo {
    svg {
      width: 38% !important;
      margin: 40px 10px auto;

      path {
        fill: #fff;
      }
    }
  }
}

// ----------------- navbar for top of each tab ----------------- //
.dashboard-control-navbar {
  background-color: @gray-lighter;
  border: 0;
  border-radius: 0;
  margin-left: -20px;
  margin-right: -20px;
  color: black;

  // Horizontal/vertical spacing for elements in the navbar
  .control-navbar-element {
    margin: 3px 20px 3px 0px;
  }

  // Print page button
  .btn-print {
    padding: 5px 8px;
    height: 34px;
    font-size: 18px;
  }

  h1 {
    font-size: 24px;
    font-weight: 300;
    line-height: 0px;
    margin-top: 28px;
    margin-bottom: 28px;
  }

  form {
    margin-right: 0px;
    padding-right: 5px;
  }
}

// -----------------  ----------------- //

.section-title {
  margin: 20px 0px 20px 15px;
}

.li-chart-legend {
  margin-top: 15px;
  margin-left: 30px;
  display: inline-block;
}

.chart-legend {
  margin-top: 0px;
  margin-left: 30px;
}

.arrow-placement {
  left: 5px;
  top: 3px;
  cursor: pointer;
}

.btn-patientback {
  color: @gray;
  text-decoration: none;
}

.btn-patientback:hover {
  color: white;
  text-decoration: none;
}

.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
}

.panel-headline {
  background: @gray-lighter;
  padding-top: 5px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 5px;
  margin-left: -20px;
  margin-right: -20px;
  height: 62px;

  .dashboard-toggle {
    margin-top: 10px;

    .btn-print {
      padding: 5px 8px;
      height: 34px;
      font-size: 18px;
    }
  }
  h1 {
    color: black;
    font-size: 24px;
    margin-top: 15px;
    font-weight: 400;
  }
}

.alert {
  border-radius: 0px;
  color: black;
}

.in-house {
  text-align: center;
}

.location {
  font-weight: bold;
}

.location-indented {
  font-weight: bold;
  position: relative;
}

.table {
  thead > tr > th {
    padding: 10px;
  }
  tr > th {
    font-weight: normal;
  }
  tr > th.location {
    font-weight: bold;
  }
  tr > th.in-house {
    font-weight: bold;
    text-align: center;
  }
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #fff;
}
.table-striped > tbody > tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.selected-date-range-btn {
  span:not(:nth-child(3)) {
    padding-right: 10px;
  }
}

.filter-options {
  margin-left: 10px;
  .parent-item {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid white;
  }
  .parent-item:last-of-type {
    border-bottom: 0px;
  }
  li > ul {
    margin-left: 0px;
  }
  .collapse li {
    // margin-left:30px;
  }
}

.line-chart {
  canvas {
    width: 100% !important;
    height: auto;
  }

  padding: 0 (@grid-gutter-width / 2);
}

.patient-view,
.new-location,
.organization-view {
  margin-left: 20px;
}

.clinical-panel {
  width: 98%;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 50px;
  position: relative;
  float: left;
  margin-bottom: 40px;
}

.app-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.sidebar {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;

  .main-nav {
    height: -webkit-calc(~"100% - 204px");
    height: -moz-calc(~"100% - 204px");
    height: calc(~"100% - 204px");
    -ms-overflow-style: none;
    overflow: auto;
  }
}

.content {
  overflow: hidden;
  padding-right: 20px;
  padding-left: 20px;
  height: 100vh;
  overflow-y: scroll;

  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.chart-container {
  margin-bottom: 30px;
}

.dropdown-toggle {
  cursor: pointer;
}

.tab-content-panel {
  width: 999999px;
  transition: left 1s ease;
}

.tab-content-container {
  float: left;
  display: inline-block;
}

.list-unstyled {
  li {
    margin-bottom: 5px;
  }
}

.bold-text {
  font-weight: bold;
}

// ------- QA notes ---------

#qa-tooltip {
  .tooltip-inner {
    background-color: #e9e7fd;
    color: #000;
  }

  .tooltip-arrow {
    border-left-color: #e9e7fd;
  }
}

// ---------- Charts ------------
.chart-legend {
  .badge-better {
    background: #59ad20;
  }

  .badge-stable {
    background: #00a38a;
  }

  .badge-stalled {
    background: #f4b63f;
  }

  .badge-worse {
    background: #ef8935;
  }

  .badge-new {
    background: #e9332c;
  }

  .badge-unknown {
    background: #000000;
  }

  .badge-monitoring {
    background: grey;
  }
}

.headline {
  margin-bottom: 20px;
}

@media print {
  .app-container {
    margin-left: 0px !important;
  }
  #sidebar-container {
    display: none;
  }
  .sidebar-toggle {
    display: none !important;
  }
  #container {
    display: block;
  }
}
