.search-bar {
  min-width: 200px;
  max-width: 400px;
  margin: 2px 0 2px 0;
  border: 1px grey;

  label {
    font-weight: 300;
    margin-left: 8px;
  }

  input {
    border-radius: 5px;
  }
}
