.file-input {
  width: 100%;
}

.margin-bottom15px {
  margin-bottom: 15px;
}

.label-text {
  margin-bottom: 5px;
  font-weight: 500;
}
