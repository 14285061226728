table.population-tally-table {
  overflow-x: auto;
  thead {
    th {
      padding: 5px;
      text-align: center;
      font-weight: 500;
      border-bottom: 2px solid #000;
    }
  }

  tbody {
    tr {
      text-align: center;
      td {
        border-top: 0px;
      }
    }
    tr:nth-child(2n + 1) {
      .dark-background {
        background-color: darken(#ffffff, 5%);
      }
    }
    tr:nth-child(2n) {
      background-color: darken(#ffffff, 3%);

      .dark-background {
        background-color: darken(#ffffff, 10%);
      }
    }
  }
}

.right-border {
  border-right: 1px solid #000;
}

.total-row {
  border-bottom: 2px solid #000;
}

.wound-type-row {
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}

.left-align {
  text-align: left;
}

.left-align-indent {
  text-align: left;
  padding-left: 25px !important;
  font-style: italic;
}

.floatRight {
  float: right;
}
.margin25px {
  margin: 25px 0 25px 0;
}
