.download-manager-list {
  list-style-type: none;
  padding: 0px;
  margin: 0px;

  li {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #eeeeee;
  }

  li:last-child {
    border-bottom: none;
  }
}
