.wound-details-heading {
  h2 {
    font-size: 25px;
    margin-top: 5px;
    margin-bottom: 0;
  }

  h3 {
    font-size: 18px;
    margin: 4px 0 0 0;
  }

  h4 {
    font-size: 14px;
    margin: 3px 0 0 0;
    padding: 0 0 0 0;
  }
}

.sub-title {
  color: darkgray;
}

.badge {
  display: inline-block;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  float: right;
}

.icon-small {
  width: 30px;
  height: 30px;
  fill: #333;
  margin: 20px 0px 0px;
  float: left;
}
