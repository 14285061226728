.schema-component {
  overflow-y: scroll;
  height: 625px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.wound-image-component {
  padding: 0px !important;
}

.form-submit {
  border-top: grey solid 2px;
  padding-top: 20px;
}

.required-field {
  color: red;
}
