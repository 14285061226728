.schema-title {
  display: block;
  background-color: #eee;
  color: #333;
  padding: 10px;
  font-weight: 400;
  font-size: 16px;
}

.form-input-area {
  padding-top: 15px;
  padding-right: 15px;
}

.checkbox-style {
  padding-left: 20px;
}

.label-text {
  margin-bottom: 5px;
  font-weight: 500;
  margin-left: 20px;
  width: 100% !important;
}

.required-field {
  color: red;
}

.form-container {
  overflow: hidden;
  position: relative;
}
.form-field-container {
  margin: 10px 0 10px 0;
}

.loading-overlay {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 7px darkgrey inset;
  background: rgba(0, 0, 0, 0.2) 25% 25% no-repeat;
}

.loading-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 2;
}

.loading-text {
  font-weight: bold;
}
