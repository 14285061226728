.comparison-item-container {
  width: 500px;
  margin: 15px;
  min-width: 500px;
}

.comparison-item-top-container {
  position: relative;
  background-color: #efefef;
  border-radius: 5px;
  border-style: solid;
  border-color: #d3d4d7;
  border-width: 1px;
  box-shadow: 0px 5px 8px 0px #d3d4d7;
  min-height: 770px;
}

.revision-selector-container {
  padding: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #b3b3b3;
}

.revision-assessment-answer-container {
  padding-top: 25px;
}

.x-button {
  cursor: pointer;
  position: absolute;
  transform: translate(50%, -50%);
  right: 0px;
  width: 28px;
  height: 28px;
}

.x-button:hover {
  opacity: 1;
}
