.sign-in-view {
  .sign-in-content {
    display: block;
  }

  background-color: #202b45;
  color: #fff;

  min-height: 100vh;

  .swift-logo {
    svg {
      width: 80%;
      height: 80%;
      margin: 40px auto;

      path {
        fill: #fff;
      }
    }
  }
}

.sign-in-content {
  display: none;

  a:hover,
  a:focus {
    color: #999;
  }
}
.nav-link {
  color: white;
}
.margin-top10px {
  margin-top: 10px;
}

.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: white;
  margin: 8px 0;
  &:before,
  &:after {
    content: "";
    flex-grow: 1;
    background: white;
    height: 1px;
    font-size: 0;
    line-height: 0;
    margin: 0 8px;
  }
}

.default {
  width: 150px;
  margin-right: 5px;
}
.activeClass {
  pointer-events: none;
}
.buttonBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
