.icon-wrapper {
  display: inline;
}

// Styles used in other components and passed to the GlyphComponent
// TODO: these styles shouldn't be used outside of this module
.arrow-placement {
  display: flex;
  align-items: center;
  padding-bottom: 3px;
  padding-left: 5px;
  text-decoration: none;
  cursor: pointer;
}

.panel-arrow {
  position: relative;
  padding-right: 5px;
}

.margin-right5px {
  margin-right: 5px;
}
