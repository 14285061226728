.no-style-list {
  list-style-type: none;
  padding-left: 0px;
}

.load-more-container {
  width: 100%;
  height: 40px;
  padding-top: 40px;
  padding-bottom: 100px;
  text-align: center;
}

.load-more-button {
  min-width: 500px;
}
