.margin-right5px {
  margin-right: 5px !important;
}
.margin-bottom10px {
  margin-bottom: 10px !important;
}
.lower-case-class {
  text-transform: lowercase;
}

.users-table-actions-container {
  display: flex;
}
