.section-title {
  margin: 30px 0px 35px 0px !important;
}
.download-icon-list {
  padding-left: 0px !important;
  float: left;

  li {
    padding: 5px;
    float: left;
    width: 100%;
    a {
      color: #999999;
      cursor: pointer;
    }
    :hover {
      background-color: #cdcdcd;
    }
  }
}
.textLeft {
  text-align: left;
}
