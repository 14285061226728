.filter-component-list {
  padding: 0px;
  margin-bottom: 10px;

  li {
    border-bottom: 1px solid white;
    padding: 5px 0px 5px 0px;

    li {
      border-bottom: 0px;
      padding: 0px;
    }
  }
  li a {
    color: white;
  }
}

.margin-top-bottom {
  margin: 20px 0 20px 0;
}
