.reset-password-view {
  color: #fff;

  min-height: 100vh;
}
.margin-bottom10px {
  margin-bottom: 10px !important;
}
.margin-top10px {
  margin-top: 10px !important;
}
