.modalStyle {
  position: absolute;
  top: 100px;
  right: 100px;
  bottom: 0;
  left: 50px;
  overflow: auto;
  overflow-y: auto;
}

.assessment-image {
  display: block;
  height: 80px;
  width: 80px;
  cursor: pointer;

  background-position: center;
  background-color: #ccc;
  background-size: cover;
}

.wound-image {
  width: 80px;
  height: 80px;
  cursor: pointer;

  background-position: center center;
  background-size: cover;
  background-color: #ccc;
}

.modal-image {
  width: 400px;
  margin: 15px;
  max-width: 400px;
}

.revision-table-header {
  background: yellow;
  tr {
    background: yellow;
  }
}

.revision-table-row {
  td:not(:nth-child(1)):not(:nth-child(2)) {
    height: 80px;
    max-width: 90px;
    margin-right: 2px;
  }

  td:last-child {
    width: 40px;
  }
}

.pdf-button {
  height: 50px;
  background-color: #0057b8;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  margin-top: 15px;
}

.pdf-button-container {
  min-width: 110px;
}

.icon-wrapper {
  min-width: 20px;
  max-width: 30px;
}
