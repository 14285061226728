@import "../../css_attributes/variables.less";

.patient-filter-container {
  ul {
    padding: 0px;
    list-style-type: none;
  }

  li {
    background-color: #fff;

    &:nth-child(even) {
      background-color: @table-bg-accent;
    }

    &:hover {
      background-color: @table-bg-hover;
    }
  }

  a {
    color: #000;
    padding: 10px 20px;
  }
}

.active {
  background-color: #e4dcdc !important;
}

.load-more-container {
  width: 100%;
  height: 40px;
  padding-top: 20px;
  padding-bottom: 50px;
}
