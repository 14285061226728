.series-info-container {
  margin-bottom: 5px;
}

.series-info-wound-type {
  font-size: 20px;
  color: #555;
}

.series-info {
  color: darkGray;
}
