.risk-dashboard {
  .patient-list {
    padding: 0;

    li {
      display: inline-block;
      vertical-align: top;
      width: 250px;
      height: 230px;
      padding: 10px;
      margin: 10px 20px 20px 0;
      // border: 1px solid #CCC;
      border-radius: 10px;
      box-shadow: 0 0 3px #CCC;
      // border-bottom: 2px solid #CCC;
    }

    h4 {
      margin: 0 0 4px 0;
      padding-top: 1px;
      font-size: 16px;
      word-break: break-word;
    }

    p {
      color: #666;
      margin: 0 0 2px 0;
    }

    .last-assessed, .next-assessed {
      font-size:12px;
    }

    .btn-link:hover {
      text-decoration: none;
    }

    .view-more-button {
      vertical-align: middle;
    }

    .view-more-plus {
      margin-top: 40px;
      font-size: 40px;
      display: block;
    }
  }

  .empty-patients-list {
    margin: 40px auto;
    text-align: center;
  }

  .headline {
    margin-bottom: 20px;
  }

  .breadcrumb {
    margin: 0 15px 30px;
  }

  .nav.nav-tabs {
    margin-bottom: 10px;

    a {
      border-radius: 20px 20px 0 0;
    }

    h3 {
      margin: 10px 10px 5px;

      .tab-label {
        font-size: 20px;
        white-space: nowrap;
      }
    }
  }

  .badge {
    width: 20px;
    height: 20px;
    position: relative;
    margin-right: 5px;
    top: 5px;
    display: inline-block;
  }

  .badge-riskNone {
    background: #000000;
  }

  .badge-riskNo {
    background: #59ad20;
  }

  .badge-riskLow {
    background: #00a38a;
  }

  .badge-riskMedium {
    background: #F4B63F;
  }

  .badge-riskHigh {
    background: #EF8935;
  }

  .badge-riskVeryHigh {
    background: #E9332C;
  }
}
