.header-date-range {
  margin-right: 8px;
  margin-top: 3px;
}

.margin-download-button {
  margin-top: 3px;
}

.table-navigator-button {
  padding: 0px;
  margin: 0px !important;
}

@light-blue: #e2e2e2;
@mid-blue: #aaaaaa;
@dark-blue: #202b45;

.legend-in-progress {
  background-color: @light-blue;
}

.legend-signed {
  background-color: @mid-blue;
}

.legend-completed {
  background-color: @dark-blue;
}

.legend-box {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 6px;
}

.divisor {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 24px;
  padding: 0px;
}

.pagination-section {
  margin-top: 40px;
}

.legend-text {
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 20px;
  vertical-align: middle;
}

.margin-bottom-40px {
  margin-bottom: 20px;
}

.margin-right {
  margin-right: 8px;
}

.float-right {
  float: right;
  line-height: 34px;
  vertical-align: middle;
}

.margin-top-14px {
  margin-top: 14px;
}

@dark-blue: #4a5b85;

.reduced-line-height {
  line-height: 1;
}

.total-evaluations {
  font-size: 12px;
  color: gray;
  margin-bottom: 0;
}

.clinicians-info {
  font-size: 18px;
  font-weight: 500;
}

.container {
  display: flex;
  overflow: hidden;
  align-items: center;
  margin-bottom: 15px;
}

.flex-item-1 {
  flex-basis: 300px;
  vertical-align: middle;
}

.flex-item-2 {
  flex-grow: 2;
  padding-top: 30px;
}

.no-evaluations {
  width: 100%;
  margin-left: 40px;
  text-align: center;
  font-size: 18px;
  color: gray;
  margin-bottom: 0px;
  height: 80px;
  padding-top: 20px;
}

.filter-flex-box {
  margin-bottom: 20px;
}

.margin-download-button {
  margin-top: 3px;
}

.search-bar {
  min-width: 200px;
  max-width: 400px;
  margin: 2px 0 2px 0;
  border: 1px grey;

  label {
    font-weight: 300;
    margin-left: 8px;
  }

  input {
    border-radius: 5px;
  }
}

.filter-component-list {
  padding: 0px;
  margin-bottom: 10px;

  li {
    border-bottom: 1px solid white;
    padding: 5px 0px 5px 0px;

    li {
      border-bottom: 0px;
      padding: 0px;
    }
  }
  li a {
    color: white;
  }
}

.bottom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lock-modal {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
}
