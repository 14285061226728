button {
  margin-right: 5px !important;
}
.group-textbox {
  input[type="text"] {
    width: 98%;
    margin-right: 5px;
    margin-bottom: 15px;
    border-radius: 5px !important;
    border-radius: 5px !important;
  }
  width: 100% !important;
  display: flex !important;
}

.group-textbox > div {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: auto;
}

.margin-bottom15px {
  margin-bottom: 15px;
}
.borderBottom1px {
  border-bottom: 1px solid #6c757d;
}
.margin-bottom25px {
  margin-bottom: 25px;
}

.label-text {
  margin-bottom: 5px;
  font-weight: 500;
  text-align: right !important;
  width: 100% !important;
}
.margin-left15px {
  margin-left: 15px !important;
}

.style-buttons {
  padding-bottom: 30px !important;
}
