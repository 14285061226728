.header-container {
  padding: 20px;
}

.breadcrumb-headline {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 40px !important;
}

.margintop8px {
  margin-top: 8px !important;
}

.no-assessments {
  padding: 30px;
  font-size: 16px;
}
.margintop11px {
  margin-top: 11px !important;
}
