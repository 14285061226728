@import "spin-box";

// SM Template

// for overflow scrolling
html,
body.sm-bootstrap,
.patient-detail__overflow-height {
  height: 100%;
}

#sm_nav,
#sm_header,
#sm_body,
#sm_footer {
  // margin-top: 20px;
  // margin-bottom: 20px;
}

// SM Widgets

// Group of data
.sm-panel__header {
  h2 {
    margin-bottom: 0;
  }

  h3 {
    font-size: 18px;
    margin: 4px 0 0 0;
  }
}

.sm-panel__header__button {
  margin-top: 35px;
}

.sm-panel__body {
  margin-top: 20px;

  hr {
    margin-top: 0;
  }
}

// Square block of data
.sm-data-block {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  color: #e7e7e7;
  position: relative;
  border-radius: 5px;
  background-color: #0386c9;

  .sm-data-block__header {
    position: absolute;
    top: 10px;
    width: 100%;
    color: #e7e7e7;
    line-height: 15px;
  }

  .sm-data-block__body {
    font-size: 40px;
  }

  .sm-data-block__footer {
    display: block;
    position: absolute;
    bottom: 0;
    font-size: 10px;
    width: 100%;
    height: 30px;
    line-height: 30px;
  }
}

.sm-modal-container {
  position: absolute;
  top: 0;
  background-color: transparent;

  .modal-dialog {
    background-color: white;
    border: 5px solid orange;
    border-radius: 10px;
  }
}

// Loader

.sm-spinner {
  width: 70px;
  display: inline-block;
  margin-left: 5px;
}

.sm-spinner > div {
  width: 10px;
  height: 10px;
  margin: 0 2px;
  background-color: #ffffff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
  animation: bouncedelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.sm-spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.sm-spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

// Scrollable List
.sm-scrollable-list {
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid #d7d7d7;
}

.sm-scrollable-list-item {
  margin: 5px 0 5px 0;
  padding: 1em;
}

.sm-scrollable-list-item--active {
  background-color: #e7e7e7;
}

.sm-bootstrap {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;

  .navbar-form {
    padding-right: 0px;
    padding-top: 2px;

    .form-control {
      padding: 10px;
      height: 30px;
      background-color: whitesmoke;
      width: 125px;
    }

    .btn {
      background-color: transparent;
      border: none;
      width: 38px;
    }
  }

  .breadcrumb {
    margin-bottom: 0px;
  }

  // Bootstrap extensions

  .btn-primary--circle {
    border-radius: 50%;
    background-color: #0386c9;
  }

  .btn-cancel {
    color: #2780e3;
    border: none;
  }

  .alert-info {
    border-color: #0386c9;
    background-color: #0386c9;
  }

  // Forms

  .sm-has-text-feedback.has-feedback {
    .form-control {
      padding-right: 100px;
    }

    .form-control-feedback {
      width: 100px;
      margin-right: 52px;
    }
  }

  //  Stats

  .header-stats {
  }

  .header-stats-box {
    background-color: #244363;
    color: white;
    border-radius: 10px;
  }

  .header-stats-box__header {
    padding-top: 20px;
  }

  .header-stats-box__value {
    font-size: 40px;
  }

  .header-stats-box__footer {
    padding-bottom: 20px;
  }

  .h2 {
    color: #777;
  }

  .label {
    padding: 5px;
    margin: -5px;
  }

  .first-col {
    width: 50%;
  }

  .third-col {
    color: darkGray;
  }

  .h2-location {
    font-size: 15px;
    color: darkGray;
  }

  // Charts

  .analytics {
    .bdsm-chart-title {
      font-size: medium;
      color: #666;
    }
  }

  .bdsm-chart {
    position: relative;
  }

  .bdsm-chart-title {
    text-align: center;
    font-size: medium;
  }

  .bdsm-chart-legend {
    position: absolute;
    top: 0;
    right: 0;
  }

  .bdsm-chart-legend li {
    padding: 0px 8px;
    border-radius: 5px;
    margin-bottom: 2px;
  }

  .bdsm-chart-bottom {
    margin-top: 30px;
  }

  // Patient Header

  .patient-summary__right {
    padding: 10px 0 10px 0;
  }

  .patient__data {
    margin-left: 110px;
  }

  .patient__name {
    margin-top: 10px;
    font-size: 32px;
    line-height: 40px;
    padding-right: 0px;
  }

  .patient__age {
    margin-top: 0px;
    line-height: 10px;
    font-size: small;
    color: gray;
  }

  .patient__birth-at {
    margin-top: 20px;
    line-height: 15px;
    font-size: small;
    color: gray;
  }

  .patient__avatar i {
    width: 100px;
    height: 100px;
    font-size: 80px;
    line-height: 100px;
    background-color: #e7e7e7;
    text-align: center;
    margin: 10px 0 10px 0;
  }

  .patient__share-token {
    font-size: 32px;
  }

  .bdsm-chart-legend__braden-current {
    right: -50px;
    color: #222;
  }

  .bdsm-chart-legend .line-legend li {
    margin: 2px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .bdsm-chart-legend__product-categories-usage {
    right: auto;
    left: 5%;
  }

  .patient__length-of-stay {
    height: 50px;
    width: 50px;

    .sm-data-block__header {
      font-size: 10px;
    }

    .sm-data-block__body {
      font-size: 35px;
      line-height: 40px;
    }

    .sm-data-block__footer {
      font-size: 10px;
      height: 15px;
      line-height: 10px;
    }
  }

  .patient__nav_bar {
    button {
      border-radius: 5px;
    }
    .patient__add_status_button {
      margin-left: 40%;
    }
  }

  // Patients

  .patient-list-small {
    height: 400px;
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
    padding: 0;
  }

  .patient-list-small__item {
    padding: 20px 10px;
    border-bottom: 1px solid #e7e7e7;
  }

  .patient-list-small__col--left {
    margin-right: 10px;
  }

  .patient-list-small__col--right {
    margin-top: 4px;
  }

  .patient-list-small__avatar {
    display: block;
    width: 50px;
    height: 50px;
    background-color: black;
    border-radius: 50%;
    overflow: hidden;
  }

  .patient-list-small__title {
  }

  .patient-list-small__subtitle {
  }

  .patient-list__item {
    border-bottom: 1px solid #dddddd;
  }

  .patient-list__item--first {
    border-top: 1px solid #dddddd;
  }

  .patient-list__item--striped {
    background-color: #f9f9f9;
  }

  .patient-list {
    .patient__name {
      font-size: 16px;
      line-height: 16px;
    }

    .patient__avatar i {
      height: 50px;
      width: 50px;
      font-size: 40px;
      line-height: 50px;
    }

    .patient__name {
    }

    .patient__age {
      margin-top: 2px;
      font-size: 11px;
    }

    .patient__data {
      margin-left: 52px;
    }

    .patient__notification {
      position: absolute;
      top: 10px;
      right: 25px;
    }
  }

  .patient__info__text {
    line-height: 100px;
  }

  // Patient Details

  .patient__details {
    height: 100%;
    overflow: scroll;
  }

  .care-team {
    margin-top: 20px;
  }

  .reports {
    margin-top: 20px;
  }

  // Revisions

  canvas.revision-frame {
    position: absolute;
  }

  div.revision-frame {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #0386c9;
  }

  .revision-body h4 {
    margin-left: 6px;
    margin-bottom: 16px;
  }
  .revision-body {
    .sub-title {
      color: darkgray;
    }

    .assessments--non-wound .assessment {
      width: 60px;
      height: 60px;
      margin-top: 20px;
    }

    .assessment__image_options {
      position: absolute;
      background-color: rgba(238, 233, 233, 0.4);
      bottom: 0;
      margin-bottom: 1px;

      a {
        font-size: 200%;
      }
    }

    .assessment__score {
      width: 60px;
      line-height: 60px;
      height: 60px;
      color: white;
    }
    .assessment__score__type {
      top: 5px;
      font-size: 11px;
    }

    .assessment__score__value {
      font-size: 30px;
    }

    .tab-content {
      min-height: 500px;
    }

    .assessment__notes {
      margin-bottom: 15px;
    }
  }

  .analysis-data-group table {
    margin-bottom: 0;
  }

  .revision__tissue__colour {
    margin-bottom: 0;
  }

  .revision__tissue__colour li {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }

  // Assessments

  .assessment {
    cursor: pointer;
  }

  .assessment__type__image {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0386c9;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100px;
    height: 100px;
    opacity: 1;
    border-radius: 5px;
  }

  .assessment__score {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    color: #e7e7e7;
    position: relative;
    border-radius: 5px;
    background-color: #0386c9;
  }

  .assessment__score__type {
    position: absolute;
    top: 10px;
    width: 100%;
    color: #e7e7e7;
    line-height: 15px;
  }

  .assessment__score__value {
    font-size: 40px;
  }

  .assessment__score__units {
    display: block;
    position: absolute;
    bottom: 0;
    font-size: 10px;
    width: 100%;
    height: 30px;
    line-height: 30px;
  }

  .assessment--wound {
    .assessment__date {
      color: darkgray;
      font-size: 11px;
      padding-top: 5px;
      overflow: visible;
      white-space: nowrap;
    }

    .assessment__container {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .assessment-detail--non-wound {
    .list-group-item {
      border: 0px;

      tr:first-of-type td {
        border-top: 0px;
      }
    }

    .assessment__answers {
      margin-top: -15px;
      margin-bottom: -35px;
    }

    .assessment__score {
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #e7e7e7;
      position: relative;
      border-radius: 5px;
      background-color: #0386c9;
    }

    .assessment__score__value {
      font-size: 20px;
    }
  }

  .assessments--non-wound {
    .assessment {
      position: relative;
      width: 100px;
      height: 100px;
      margin: 5px 5px 5px 0;
    }

    .assessment__type {
      position: absolute;
      top: 5px;
      width: 100%;
      color: #e7e7e7;
    }

    .assessment__data {
      position: absolute;
      width: 100%;
      color: #e7e7e7;
      bottom: 7px;
      font-size: 11px;
    }

    .assessment--non-wound__notification {
      background-color: red;
      box-shadow: 1px 1px 2px black;
      width: 20px;
      height: 20px;
      margin-left: 90px;
      margin-top: -7px;
      border-radius: 50%;
      position: absolute;
      z-index: 1;
    }
  }

  .patient__data.container-fluid {
    padding: 0px 0px;
  }

  .assessments--wound {
    .row {
      padding-left: 0px;
    }

    .assessment__wound-type {
      font-size: 20px;
      color: #555;
    }

    .assessment__location {
      color: darkGray;
      font-size: 15px;
    }

    .assessment__type {
      padding: 0px 0px;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
      color: #e7e7e7;
      position: relative;
      background-color: #333;
      border-radius: 5px;
    }

    .assessment__type__name {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      text-align: center;
    }

    .assessment__data {
      margin-left: 110px;
      margin-right: 110px;
    }
  }

  .wound-assessment {
    .wound-assessment__header {
      .sub-title {
        color: darkgray;
      }

      .wound-assessment__header__label {
        margin-left: 110px;
      }

      .wound-assessment__header__thumbnail {
        margin-top: 21px;
      }
    }
  }

  .sm-preview-image {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .badge {
      position: absolute;
      top: 1px;
      left: 1px;
      background-color: #aaa;
      color: #eee;
      border-radius: 0px;
      padding: 3px 3px;
      line-height: 8px;
      min-width: 19px;
      font-weight: bold;
      vertical-align: middle;
    }
  }

  .sm-list-with-horizontal-scroll {
    overflow-x: scroll;
  }

  .sm-list {
  }

  .sm-list-col {
    padding: 3px;
  }

  .sm-list-col-item {
    margin-bottom: 20px;
    border-bottom: 1px solid lightgray;
    height: 70px;
  }

  .sm-list-col-item__left {
    width: 50px;
  }

  .sm-list-col-item__right {
    margin-left: 50px;

    .wound__status {
      padding-left: 0;
      font-weight: bold;
      margin-top: -5px;
    }
    .wound__age {
      font-size: 11px;
      padding-left: 0;
      color: gray;
      line-height: 8px;
    }
    .wound__patient {
      padding-left: 0;
      margin-top: 14px;
      font-size: 11px;
    }
  }

  .sm-wound-type-list {
    width: 120%;
  }

  .sm-wound-type-list__preview-image__placeholder {
    width: 50px;
    height: 50px;
  }

  // Structure

  .h2 {
    color: #333333;
  }

  // General

  .form-group--delete > div {
    opacity: 0.5;
  }

  .form-group--delete label {
    text-decoration: line-through;
  }

  .blurred-text {
    color: transparent !important;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }

  // overflow scrolling. offset alert div
  .container-fluid.patient-detail__overflow-height {
    height: calc(100% - 30px);
  }
}
