.logo {
  svg {
    width: 38% !important;
    margin: 40px 10px auto;

    path {
      fill: #fff;
    }
  }
}

.min-height {
  min-height: 100vh;
}

.margin-top-10px {
  margin-top: 10px !important;
}

.instructions {
  margin-top: 45px;
  font-size: 18px;
}

.login-link-style {
  color: white;
}
