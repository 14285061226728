.loading-container {
  padding-top: 100%;
  position: relative;
  width: 100%;
}

.progress-bar-container {
  width: 50%;
  text-align: center;
  margin: auto;
}

.content {
  padding-top: 15%;
  text-align: center;
}
