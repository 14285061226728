.patient-card {
  display: inline-block;
  padding: 10px;
  margin: 0 -10px -10px -10px;
  height: 90px;
  background-color: #f6f6f6;
  box-shadow: inset 0 3px 3px -3px #ccc;
  border-radius: 0 0 10px 10px;

  p {
    font-size: 10px;
    margin-bottom: 2px;
  }

  .avatar {
    width: 60px;
    height: 60px;
    background-position: center center;
    background-size: cover;
    background-color: #ccc;
  }
}

.media-heading {
  font-size: 14px;
  margin: 0px;
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
