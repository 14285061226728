.download-manager-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 450px;
  box-shadow: 0px 5px 8px 0px #d3d4d7;
  z-index: 999999;
}

.download-manager-body-container {
  padding: 5px 18px 5px 18px;
  min-height: 70px;
  max-height: 150px;
  background: white;
  overflow-y: auto;
}
