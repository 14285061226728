.study-badge {
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 5px;
  position: relative;
}

.badge-title {
  position: absolute;
  top: 5%;
  width: 100%;
  font-size: 0.7em;
  font-weight: 500;
}

.badge-value {
  position: absolute;
  top: 55%;
  width: 100%;
  transform: translateY(-50%);
  font-size: 1.4em;
}

.badge-bottom-text {
  position: absolute;
  bottom: 5%;
  width: 100%;
  font-size: 0.5em;
}
