.image-container {
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.innerdiv {
  color: black;
  font-size: 14px;
  text-align: center;
}
.thumbnail-border-radius {
  border-radius: 5px;
}
.imageText {
  position: absolute;
  top: 10px;
  right: 15px;
  color: black;
  font-size: 14px;
  font-weight: 500;
}
